<template>
    <v-content>
        <div class="container">
            <v-row
                    align="center"
                    justify="center"
                    class="grey lighten-5"
                    style="height: 300px;"
            >
                <v-col cols="12">
                    <v-card
                            class="ma-3 pa-6"
                            outlined
                            tile
                    >
                        <v-toolbar dark color="blue" class="darken-1">
                            <v-toolbar-title>
                                {{index +1 }} out of {{totalData}} Questions
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-title>
                                Survey
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn dark text @click="loadItems">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <h2 class="text-center">
                                {{questionData[index].questionText}}
                            </h2>
                            <template v-if="questionData[index].questionType === 'multiple'">
                                <v-row no-gutters justify="center">
                                    <v-col cols="12" sm="3">
                                        <v-checkbox class="mx-2"
                                                    label="Select All"
                                                    @change="selectAll(questionData[index].choices)"
                                                    v-model="selectAllOptions[index]"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-for="(choice,key) in questionData[index].choices"
                                           :key="key">
                                        <v-checkbox class="mx-2" :label="choice.choiceText"
                                                    v-model="submitData[index].Answers"
                                                    :value="choice"
                                                    @change="individualOptionSelect()"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="questionData[index].questionType === 'single'">
                                <v-row no-gutters justify="center">
                                    <v-col cols="12">
                                        <v-radio-group v-model="submitData[index].Answers[0]" row>
                                            <v-radio
                                                    v-for="(choice,key) in questionData[index].choices"
                                                    :key="key"
                                                    :label="choice.choiceText"
                                                    :value="choice"
                                                    class="pa-3"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="questionData[index].questionType === 'text'">
                                <v-row no-gutters justify="center">
                                    <v-col cols="12">
                                        <v-textarea
                                                name="input-7-1"
                                                label="Type Your Answer"
                                                value=""
                                                v-model="submitData[index].Answers[0].choiceText"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="questionData[index].questionType === 'date'">
                                <v-row no-gutters justify="center">
                                    <v-col cols="12">
                                        <v-menu
                                                v-model="snackbar.dateMenu"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="submitData[index].Answers[0].choiceText"
                                                        label="Choose Date"
                                                        clearable
                                                        prepend-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="submitData[index].Answers[0].choiceText">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="decreaseIndex" :disabled="invalidIndexLower">Previous</v-btn>
                            <v-btn color="primary" @click="increaseIndex" :disabled="invalidIndexUpper">Next
                            </v-btn>
                            <v-btn color="success" @click="submit" :disabled="!invalidIndexUpper">Submit
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>

            </v-row>
            <v-snackbar
                    v-model="snackbar.snackbar"
                    :color="snackbar.color"
                    :timeout="snackbar.timeout"
                    top
            >
                {{ snackbar.text }}
                <v-btn
                        dark
                        text
                        @click="snackbar.snackbar = false"
                >
                    Close
                </v-btn>
            </v-snackbar>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "QuestionList",
        components: {},
        computed: {
            invalidIndexLower() {
                return this.index === 0;
            },
            invalidIndexUpper() {
                return this.index === this.totalData - 1;
            },
        },
        data() {
            return {

                questionData: [
                    {}
                ],
                index: 0,
                totalData: 0,
                submitData: [],
                selectAllOptions: [],
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    dateMenu: false,
                    breadcrumbItems: [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            to: '',
                            exact: true
                        },
                        {
                            text: 'Survey',
                            disabled: true,
                        },
                    ],
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            };
        },
        methods: {
            individualOptionSelect() {
                this.selectAllOptions[this.index] = false
                /*if( this.ChoiceID.hasOwnProperty(index) ){
                    alert(this.ChoiceID[index]);
                    alert(id);
                }*/
                /*var filtered = this.ChoiceID.filter(function (el) {
                    return el != null;
                });
                this.ChoiceID = filtered*/
            },
            selectAll(arrayOfObject) {
                console.log('this.selectAllOptions[this.index]', this.selectAllOptions[this.index]);
                if (!this.selectAllOptions[this.index]) {
                    this.submitData[this.index].Answers = []
                } else {
                    this.submitData[this.index].Answers = []
                    this.submitData[this.index].Answers = arrayOfObject
                    /*arrayOfObject.forEach((arrayItem) => {
                        this.submitData[this.index].Answer.push(arrayItem.choiceID)
                    });*/
                }
            },
            decreaseIndex() {
                this.index--
            },
            submit() {
                axios.post("Survey/InsertIntoSurveyAnswersAsync", this.submitData).then(response => {
                    if (response.data.success) {
                        this.snackbar.servicesData.data = response.data
                        this.snackbar.servicesData.message = "Survey Completed Successfully"
                        this.snackbar.servicesData.color = "success"
                        this.loadItems()
                    }
                }).catch(response => {
                    this.snackbar.servicesData.data = response.data
                    this.snackbar.servicesData.message = "Error Submitting Survey, Please Contact Admin"
                    this.snackbar.servicesData.color = "error"
                });

            },
            increaseIndex() {
                this.index++
                if (!this.submitData[this.index]) {
                    this.submitData.push({
                        'QuestionID': this.questionData[this.index].questionID,
                        'AnswerType': this.questionData[this.index].questionType,
                        'Answers': [{}]
                    })
                    this.selectAllOptions.push(false)
                }
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let params = {
                    projectID: this.$route.params.projectId
                }
                const questionData = await axios.post('Survey/GetSurveyQuestionsToAnswer', params)
                this.questionData = questionData.data
                this.totalData = questionData.data.length
                this.index = 0
                this.selectAllOptions = []
                this.submitData = []
                this.submitData.push({
                    'QuestionID': this.questionData[0].questionID || null,
                    'AnswerType': this.questionData[0].questionType || '',
                    'Answers': [{}]
                })
                this.snackbar.loading = false;
            },
        },
        mounted() {
            this.loadItems()
        },
    }
</script>

<style scoped lang="scss">
    .field_container {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 2px;
    }
</style>